import { useCartStore, useUIStore } from "../../../Store"

export const TotalSidebar = ({ total, setComprar }) => {

  const closeMenu = useUIStore(state => state.closeSideMenu)
  const car = useCartStore(state => state.cart)

  
  const handleSubmit = () => {

    const dataProducts = car.map((e) => (
      {
        id: e.id,
        qty: e.qty
      }
    ))

    const data = {
      nick: "user1",
      id_user: "64y6365a-d2c6-4ce3-95d3-e15aa25b65ap",
      id_products: JSON.stringify(dataProducts)
    }
    
    setComprar(data)
    
  }



  return (
    <div className=" border-y mt-5 py-3">

      <div className="flex flex-wrap justify-between mb-3">
        <span className='text-2xl font-bold' >
          Total:
        </span>
        <span className='text-2xl font-bold text-green-50'>
          ${total}
        </span>
      </div>

      <button className="btn-primary w-full" onClick={ () => handleSubmit() } >
        Comprar Ahora
      </button>
    </div>
  )

}